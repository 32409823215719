import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { updateRole } from "@/service/roles";
import { useGlobalStore } from "@/stores/global-store";
import { apolloClient, nhost } from "../main";
 // @ts-ignore
import { nhostAuth } from "@baymetrics/trustech"; 
import { validateMFA } from "@/api/ts/crud/user";


const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: "notFound" },
  },
  {
    path: "/",
    redirect: { name: "login" },
  },
  {
    path: "/",
    children: [
      {
        path: "/",
        meta: {
          requiresNotAuth: true,
        },
        children: [
          {
            path: "login",
            name: "login",
            component: () => import("@/views/pages/auth/Login.vue"),
          },
          {
            name: "login-magic",
            path: "login-magic",
            component: () => import("@/views/pages/auth/LoginMagicLink.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/magic/:ticket/:redirectTo",
    name: "home-magic",
    component: () => import("@/views/MagicRedirect.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const globalStore = useGlobalStore();
  if (from?.fullPath && to.fullPath !== from?.fullPath) {
    globalStore.hideLoading();
  }
  const nhost = await nhostAuth(); 
  console.log(await nhost.auth.isAuthenticatedAsync())
  const requiresAuth = to.meta.requiresAuth;
  const requiresNotAuth = to.meta.requiresNotAuth;
  const isAuthenticated = await nhost.auth.isAuthenticatedAsync();
  const mfaValidated = globalStore.mfaValidated;

  if (requiresAuth && isAuthenticated && mfaValidated) {
    try {
      // Chamada para obter a expiração do código
      const { user } = await validateMFA(nhost.auth.getUser().id);
      const expiresAt = new Date(user?.otpHashExpiresAt);
      const now = new Date();

      if (!user?.otpHashExpiresAt || now > expiresAt) {
        console.warn("Código expirado. Redirecionando...");
        await nhost.auth.signOut();
        globalStore.setMfaValidated(false);
        return next({ name: "login" });
      }

    } catch (err) {
      console.error("Erro ao validar MFA na rota:", err);
      await nhost.auth.signOut();
      globalStore.setMfaValidated(false);
      return next({ name: "login" });
    }
  }

  if (requiresAuth && (!isAuthenticated || !mfaValidated)) {
    next({ name: "login" });
    return;
  }

  if (requiresNotAuth && isAuthenticated) {
    if (!mfaValidated && to.name === "login") {
      return next(); 
    }
    
    const paramsNextTo = to.query.nextTo;
    const params = paramsNextTo ? `?nextTo=${paramsNextTo}` : "";
    window.location.href = `/${params}`;
    return;
  }

  next();
});

export default router;
