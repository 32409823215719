import { gql } from "graphql-tag";

export const SELECT_USER_MODULES = gql`
  query MyQuery($id: uuid!) {
    user_module(where: { id_user: { _eq: $id } }) {
      id
      module {
        id
        module
      }
      user {
        displayName
        email
      }
    }
  }
`;

export const SELECT_USER_MODULES_NOT_EQUAL = gql`
  query MyQuery($id: uuid!) {
    user_module(where: { id_user: { _eq: $id } }) {
      id_module
    }
    modules {
      id
      module
    }
  }
`;

export const INSERT_USER_MODULES = gql`
  mutation MyMutation($objects: [user_module_insert_input!]!) {
    insert_user_module(objects: $objects) {
      returning {
        id
        id_module
        id_user
      }
    }
  }
`;
export const DELETE_USER_MODULE = gql`
  mutation MyMutation($id: Int!) {
    delete_user_module_by_pk(id: $id) {
      id
    }
  }
`;

export const INSERT_USER_AND_TENANT = gql`
  mutation InsertUsers($users: [users_insert_input!]!) {
    insertUsers(objects: $users) {
      returning {
        id
        displayName
        email
        emailVerified
        user_module_roles {
          role
        }
        phoneNumber
        locale
        avatarUrl
      }
    }
  }
`;

export const UPDATE_USER_AND_TENANT = gql`
  mutation UpdateUser($id: uuid!, $user: users_set_input!) {
    updateUser(pk_columns: { id: $id }, _set: $user) {
      id
      displayName
      email
      emailVerified
      user_module_roles {
        role
      }
      phoneNumber
      locale
      avatarUrl
    }
  }
`;

export const SELECT_USERS_FROM_TENANT = gql`
  query SelectUsersFromTenant($id_tenant: uuid!) {
    users(where: { tenants_user: { id_tenant: { _eq: $id_tenant } } }) {
      id
      displayName
      email
      emailVerified
      disabled
      user_module_roles {
        role
      }
      phoneNumber
      locale
      avatarUrl
    }
  }
`;

export const INSERT_USER = gql`
  mutation InsertUsers($users: [users_insert_input!]!) {
    insertUsers(objects: $users) {
      returning {
        id
        displayName
        email
        emailVerified
        user_module_roles {
          role
        }
        phoneNumber
        locale
        avatarUrl
      }
    }
  }
`;

export const INSERT_USER_INFO = gql`
  mutation InsertUsers($user_info: [user_info_insert_input!]!) {
    insert_user_info(objects: $user_info) {
      returning {
        type
        exp_date
        manager {
          id
          displayName
        }
        matricula
        group {
          description
        }
      }
    }
  }
`;

export const SELECT_USERS = (isBayAdmin = false) => {
  let query = isBayAdmin
    ? "users"
    : 'users(where: { user_module_roles: {  role: { _neq: "tenant_admin_bay" }}})';
  return gql`
  query SelectUsers {
    ${query} {
      id
      displayName
      email
      emailVerified
      disabled
      user_module_roles { role }
      locale
      phoneNumber
      createdAt
      updatedAt
      avatarUrl
      user_info {
        type
        exp_date
        manager {
          id
          displayName
        }
        matricula
        group {
          description
        }
      }
    }
    group {
      id
      description
    }
    authRoles {
      role
    }
  }
`;
};
export const SELECT_ADMINS = gql`
  query SelectUsers {
    users(where: { user_module_roles: { role: { _in: "tenant_admin" } } }) {
      id
      displayName
      email
      emailVerified
      user_module_roles {
        role
      }
      locale
      phoneNumber
      createdAt
      updatedAt
      avatarUrl
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: uuid!
    $displayName: String!
    $email: citext
    $phoneNumber: String!
    $defaultRole: String!
    $locale: String!
    $avatarUrl: String!
  ) {
    updateUser(
      pk_columns: { id: $id }
      _set: {
        displayName: $displayName
        email: $email
        phoneNumber: $phoneNumber
        defaultRole: $defaultRole
        locale: $locale
        avatarUrl: $avatarUrl
      }
    ) {
      id
      displayName
      email
      emailVerified
      user_module_roles {
        role
      }
      locale
      phoneNumber
      avatarUrl
      user_info {
        type
        exp_date
        manager {
          id
          displayName
        }
        matricula
        group {
          description
        }
      }
    }
  }
`;

export const UPDATE_USER_C = gql`
  mutation UpdateUser(
    $id: uuid!
    $displayName: String!
    $email: citext
    $phoneNumber: String!
    $defaultRole: String!
  ) {
    updateUser(
      pk_columns: { id: $id }
      _set: {
        displayName: $displayName
        email: $email
        phoneNumber: $phoneNumber
        defaultRole: $defaultRole
      }
    ) {
      id
      displayName
      email
      disabled
      emailVerified
      user_module_roles {
        role
      }
      phoneNumber
    }
  }
`;

export const DACTIVATE_USER_C = gql`
  mutation UpdateUser($id: uuid!, $disabled: Boolean) {
    updateUser(pk_columns: { id: $id }, _set: { disabled: $disabled }) {
      id
      displayName
      email
      disabled
      emailVerified
      user_module_roles {
        role
      }
      phoneNumber
    }
  }
`;

export const UPDATE_USER_INFO = gql`
  mutation UpsertUserInfo(
    $id: uuid!
    $type: bpchar!
    $exp_date: date
    $matricula: String
    $id_group: uuid!
  ) {
    insert_user_info(
      objects: [
        {
          user_id: $id
          type: $type
          exp_date: $exp_date
          matricula: $matricula
          id_group: $id_group
        }
      ]
      on_conflict: {
        constraint: user_info_pkey # Replace 'user_info_pkey' with the actual primary key constraint name
        update_columns: [type, exp_date, matricula, id_group]
      }
    ) {
      returning {
        type
        exp_date
        matricula
        group {
          description
        }
      }
    }
  }
`;

export const DELETE_USER_ORG = gql`
  mutation DeleteUserOrg($id: uuid!) {
    delete_user_organization_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: uuid!) {
    deleteUser(id: $id) {
      id
    }
  }
`;

export const SELECT_USERS_TENANT_ADMIN = gql`
  query SelectUsers {
    users(where: { user_module_roles: { role: { _in: "tenant_admin" } } }) {
      id
      displayName
      email
      emailVerified
      user_module_roles {
        role
      }
    }
  }
`;

export const SELECT_USERS_ORG_POSSIBLE_DPO = gql`
  query SelectOrgApprovers {
    users(
      where: { user_module_roles: { role: { _in: ["admin", "gestor"] } } }
    ) {
      id
      displayName
      email
      emailVerified
      user_module_roles {
        role
      }
      avatarUrl
    }
  }
`;

export const SELECT_USERS_ORG_APPROVERS = gql`
  query SelectPossibleApprovers($id_org: uuid!) {
    user_organization(where: { id_organization: { _eq: $id_org } }) {
      id
      user {
        id
        displayName
        email
        emailVerified
        user_module_roles {
          role
        }
        avatarUrl
      }
    }
  }
`;

export const INSERT_APP_ORG = gql`
  mutation InsertAppOrg($id_org: uuid!, $id_user: uuid!) {
    insert_user_organization(
      objects: { id_organization: $id_org, id_user: $id_user }
    ) {
      returning {
        id
        user {
          id
          displayName
          email
          emailVerified
          user_module_roles {
            role
          }
          avatarUrl
        }
      }
    }
  }
`;

export const UPDATE_USER_DPO_ORG = gql`
  mutation UpdateDpoOrganization($id: uuid!, $id_user: uuid!) {
    update_organization(
      where: { id: { _eq: $id } }
      _set: { dpo_responsible: $id_user }
    ) {
      returning {
        id
        user_dpo {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const UPDATE_USER_DPO_TENANT = gql`
  mutation UpdateDpoOrganization($id: uuid!, $id_user: uuid!) {
    update_tenant(
      where: { id: { _eq: $id } }
      _set: { dpo_responsible: $id_user }
    ) {
      returning {
        id
        user_dpo {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const UPDATE_USER_DEFAULT_RESP_ORG = gql`
  mutation UpdateDefaultResponsibleOrganization($id: uuid!, $id_user: uuid!) {
    update_organization(
      where: { id: { _eq: $id } }
      _set: { default_responsible: $id_user }
    ) {
      returning {
        id
        user_dpo {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const UPDATE_USER_DEFAULT_RESP_TENANT = gql`
  mutation UpdateDefaultResponsibleOrganization($id: uuid!, $id_user: uuid!) {
    update_tenant(
      where: { id: { _eq: $id } }
      _set: { default_responsible: $id_user }
    ) {
      returning {
        id
        user_dpo {
          id
          displayName
          email
        }
      }
    }
  }
`;

export const UPDATE_MANAGER_USER = gql`
  mutation UpdateManager($id: uuid!, $id_user: uuid!) {
    update_user_info(
      where: { user_id: { _eq: $id } }
      _set: { id_manager: $id_user }
    ) {
      returning {
        manager {
          id
          displayName
        }
      }
    }
  }
`;

export const SELECT_USER_TENANT_BY_ID = gql`
  query SelectUserById($id: uuid!) {
    users(where: { id: { _eq: $id } }) {
      id
      tenants_user_aggregate {
        nodes {
          tenant {
            url
            name
            id
          }
        }
      }
    }
  }
`;

export const SEND_EMAIL_MFA_CODE = gql`
  mutation SEND_EMAIL_MFA_CODE($user_id: String!, $expires_in_30_days: Boolean!) {
    generateMfaCode(user_id: $user_id, expires_in_30_days: $expires_in_30_days) {
      success
      message
      expiresAt
      result
    }
  }
`;


export const SELECT_CODE_MFA = gql`
  query MyQuery($user_id: uuid!) {
    users(where: { id: { _eq: $user_id } }) {
      email
      id
      displayName
      otpHash
      otpHashExpiresAt
    }
  }
`;