import { defineStore } from "pinia";
import { ref } from "vue";

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      isSidebarMinimized: false,
      userName: "Vasili S",
      userRoles: ref<string | undefined>(),
      loadingController: false,
      leftSideMenuController: "static",
      logoTopBarController: true,
      getTheme: "light",
      currentUserModuleBelongs: "default",
      mfaValidated: localStorage.getItem("mfaValidated") === "true",
      showMfaInput: localStorage.getItem("showMfaInput") === "true",
    };
  },
  actions: {
    deleteItem(usuario: any) {
      return usuario === "admin";
    },
    showLoading() {
      this.loadingController = true;
      document.body.style.overflow = "hidden";
    },
    hideLoading() {
      this.loadingController = false;
      document.body.style.overflow = "auto";
    },
    toggleLeftSideMenu(value: string) {
      return (
        (this.leftSideMenuController = value),
        (this.logoTopBarController = value !== "static")
      );
    },
    toggleTheme() {
      this.getTheme = this.getTheme === "light" ? "dark" : "light";
    },
    setCurrentUserModuleBelongs(value: string) {
      this.currentUserModuleBelongs = value || "default";
    },

    // MFA flags
    setMfaValidated(value: boolean) {
      this.mfaValidated = value;
      localStorage.setItem("mfaValidated", value ? "true" : "false");
    },
    setShowMfaInput(value: boolean) {
      this.showMfaInput = value;
      localStorage.setItem("showMfaInput", value ? "true" : "false");
    },
    clearMfaFlags() {
      this.mfaValidated = false;
      this.showMfaInput = false;
      localStorage.removeItem("mfaValidated");
      localStorage.removeItem("showMfaInput");
    },
  },
});
