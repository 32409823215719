import {
  useQuery,
  useMutation,
  provideApolloClient,
} from "@vue/apollo-composable";
import {
  SELECT_ADMINS,
  SELECT_USERS,
  SELECT_USER_TENANT_BY_ID,
  SELECT_USERS_TENANT_ADMIN,
  SELECT_USERS_ORG_APPROVERS,
  SELECT_USERS_ORG_POSSIBLE_DPO,
  INSERT_USER_AND_TENANT,
  SELECT_USERS_FROM_TENANT,
  UPDATE_USER_AND_TENANT,
  SELECT_USER_MODULES,
  SEND_EMAIL_MFA_CODE,
  INSERT_USER_MODULES,
  DELETE_USER_MODULE,
  SELECT_USER_MODULES_NOT_EQUAL,
  SELECT_CODE_MFA,
} from "@/api/queries/crud/userQ";
import { apolloClient } from "@/main";
import { nhost } from "@/main";
import { throwErrorMessage, throwSuccessMessage } from "@/service/throwMessage";

async function selectUserModules(id_user: String) {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(
      SELECT_USER_MODULES,
      {
        id: id_user,
      },
      {
        fetchPolicy: "no-cache",
      }
    )
  );
  return {
    result,
    loading,
  };
}

async function selectUserModulesNotEqual(id_user: String) {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(
      SELECT_USER_MODULES_NOT_EQUAL,
      {
        id: id_user,
      },
      {
        fetchPolicy: "no-cache",
      }
    )
  );
  while (loading.value) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
  // Extrair IDs dos módulos em user_module
  const userModuleIds = result.value.user_module.map((item) => item?.id_module);
  // Filtrar módulos que não estão em user_module
  const filteredModules = result.value.modules
    .map((module) => {
      if (!userModuleIds.includes(module.id)) {
        return {
          value: module.id,
          label: module.module,
        };
      }
    })
    .filter(Boolean);

  return filteredModules;
}

async function insertUserModules(modules: Array<Object>) {
  try {
    const { mutate: mutateInsert } = provideApolloClient(apolloClient)(() =>
      useMutation(INSERT_USER_MODULES)
    );
    const response = await mutateInsert({
      objects: modules,
    });
    const insertedData = response.data.insert_user_module.returning;
    return insertedData;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

async function deleteUserModule(id: String) {
  const { mutate } = provideApolloClient(apolloClient)(() =>
    useMutation(DELETE_USER_MODULE)
  );
  try {
    const response = await mutate({
      id: id,
    });
    return response.data.delete_user_module_by_pk;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

async function selectUsers(isReload: number, role = null) {
  let query = SELECT_USERS(true);
  if (isReload) return await reSelectUsers();
  if (role) {
    query = SELECT_USERS(["tenant_admin_bay", "admin"].includes(role));
  }
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(query)
  );
  return {
    result,
    loading,
  };
}

async function reSelectUsers() {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(
      SELECT_USERS,
      {},
      {
        fetchPolicy: "no-cache",
      }
    )
  );
  return {
    result,
    loading,
  };
}

async function selectUsersFromTenant(id_tenant: String) {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(
      SELECT_USERS_FROM_TENANT,
      {
        id_tenant: id_tenant,
      },
      {
        fetchPolicy: "no-cache",
      }
    )
  );
  return {
    result,
    loading,
  };
}

async function insertUsersAndTenant(users: Object) {
  const { mutate: mutateInsert } = provideApolloClient(apolloClient)(() =>
    useMutation(INSERT_USER_AND_TENANT)
  );
  try {
    const response = await mutateInsert({
      users: users,
    });
    const insertedData = response.data.insertUsers.returning;
    return insertedData;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

async function updateUserAndTenant(id: String, user: Object) {
  const { mutate: mutateUpdate } = provideApolloClient(apolloClient)(() =>
    useMutation(UPDATE_USER_AND_TENANT)
  );
  try {
    const response = await mutateUpdate({
      id: id,
      user: user,
    });
    const updatedData = response.data.updateUser;
    return updatedData;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

async function insertUsers(users: Object, mutate: any) {
  try {
    const response = await mutate({
      users: users,
    });
    const insertedData = response.data.insertUsers.returning;
    return insertedData;
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function insertUserInfo(user_info: Object, mutate: any) {
  try {
    const response = await mutate({
      user_info: user_info,
    });
    const insertedData = response.data.insert_user_info.returning;
    return insertedData;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function upsertUserInfo(
  id_user: String,
  type: String,
  exp_date: Date | null | undefined,
  matricula: String | null | undefined,
  id_group: String,
  mutate: any
) {
  try {
    const response = await mutate({
      id: id_user,
      type: type,
      exp_date: exp_date,
      matricula: matricula,
      id_group: id_group,
    });
    const updatedData = response.data.insert_user_info.returning[0];
    return updatedData;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function selectAdmins() {
  const { result, loading } = await useQuery(SELECT_ADMINS);
  return {
    result,
    loading,
  };
}

async function updateUserC(
  id: String,
  displayName: String,
  email: String,
  phoneNumber: String | null | undefined,
  defaultRole: String,
  mutate: any
) {
  try {
    const data = await mutate({
      id: id,
      displayName: displayName,
      email: email,
      phoneNumber: phoneNumber,
      defaultRole: defaultRole,
    });
    if (data) return data;
  } catch (error) {
    console.log(error);
    return error;
  }
  return false;
}

async function updateUser(
  id: String,
  displayName: String,
  email: String,
  phoneNumber: String | null | undefined,
  defaultRole: String,
  avatarUrl: String,
  locale: String,
  mutate: any
) {
  try {
    const data = mutate({
      id: id,
      displayName: displayName,
      email: email,
      phoneNumber: phoneNumber,
      defaultRole: defaultRole,
      avatarUrl: avatarUrl,
      locale: locale,
    });
    if (data) return data;
  } catch (error) {
    console.log(error);
  }
  return false;
}

async function deleteUser(id: String, mutate: any) {
  try {
    const deletedUser = await mutate({
      id: id,
    });
    return deletedUser;
  } catch (error) {
    return false;
  }
}

async function dActivateUser(id: String, isDisabled: boolean, mutate: any) {
  try {
    const data = await mutate({
      id: id,
      disabled: isDisabled,
    });
    if (data) return data;
  } catch (error) {
    console.log(error);
  }
  return false;
}

async function deleteUserOrg(id: String, mutate: any) {
  try {
    const deletedUserOrg = await mutate({
      id: id,
    });
    return deletedUserOrg;
  } catch (error) {
    return false;
  }
}

async function selectUsersTenantAdmin() {
  const { result, loading } = await useQuery(SELECT_USERS_TENANT_ADMIN);
  return {
    result,
    loading,
  };
}

async function selectUsersOrgApp(id_org: String) {
  const { result, loading } = await useQuery(
    SELECT_USERS_ORG_APPROVERS,
    {
      id_org: id_org,
    },
    {
      fetchPolicy: "no-cache",
    }
  );
  return {
    result,
    loading,
  };
}

async function insertOrgApp(id_org: String, id_user: String, mutate: any) {
  try {
    const response = await mutate({
      id_org: id_org,
      id_user: id_user,
    });
    const insertedData = response.data.insert_user_organization.returning;
    return insertedData[0];
  } catch (error) {
    console.log(error);
  }
}

async function selectPossibleDpoUsers() {
  const { result, loading } = await useQuery(SELECT_USERS_ORG_POSSIBLE_DPO);
  return {
    result,
    loading,
  };
}

async function updateDefaultResponsible(
  id: String,
  id_user: String,
  mutate: any
) {
  try {
    const response = await mutate({
      id: id,
      id_user: id_user,
    });
    const updatedData = response.data;
    return updatedData;
  } catch (error) {
    console.log(error);
  }
}

async function updateUserDPO(id_org: String, id_user: String, mutate: any) {
  try {
    const response = await mutate({
      id: id_org,
      id_user: id_user,
    });
    const updatedData = response.data;
    return updatedData;
  } catch (error) {
    console.log(error);
  }
}

async function updateManager(id: String, id_user: String, mutate: any) {
  try {
    const response = await mutate({
      id: id,
      id_user: id_user,
    });
    const updatedData = response.data;
    return updatedData;
  } catch (error) {
    console.log(error);
  }
}

async function selectUserById(id: String) {
  const { result, loading } = await provideApolloClient(apolloClient)(() =>
    useQuery(
      SELECT_USER_TENANT_BY_ID,
      {
        id: id,
      },
      {
        fetchPolicy: "no-cache",
      }
    )
  );
  return {
    result,
    loading,
  };
}

async function sendEmailMFACode(user_id: String, expires_in_30_days: boolean) {
  try {
    const { mutate: mutateUpdate } = provideApolloClient(apolloClient)(() =>
      useMutation(SEND_EMAIL_MFA_CODE)
    );
    const response = await mutateUpdate({
      user_id, expires_in_30_days
    });

    const updatedData =
      response?.data?.generateMfaCode;
    return updatedData;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function validateMFA(user_id: string) {
  try {
    const { data } = await apolloClient.query({
      query: SELECT_CODE_MFA,
      variables: { user_id },
      fetchPolicy: "no-cache"
    });

    const user = data?.users?.[0]; 

    return {
      user,
      loading: false
    };
  } catch (error) {
    console.error("Erro ao validar MFA:", error);
    return {
      user: null,
      loading: false
    };
  }
}

export {
  insertUsers,
  updateManager,
  upsertUserInfo,
  insertUserInfo,
  selectAdmins,
  selectUsers,
  updateUser,
  updateUserC,
  deleteUser,
  dActivateUser,
  selectUsersTenantAdmin,
  selectUsersOrgApp,
  insertOrgApp,
  deleteUserOrg,
  selectPossibleDpoUsers,
  updateDefaultResponsible,
  updateUserDPO,
  selectUserById,
  insertUsersAndTenant,
  selectUsersFromTenant,
  updateUserAndTenant,
  selectUserModules,
  insertUserModules,
  deleteUserModule,
  selectUserModulesNotEqual,
  sendEmailMFACode,
  validateMFA
};
